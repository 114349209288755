<template>
    <painel titulo="Relatório Financeiro Consolidado" icone="pi pi-key" :mostrarRefresh=false>
        <div class="mb-4">
            <detalhe titulo="Total de Acordos" :size="sizeForm">{{ relatorioFinanceiro?.totalParceria }}</detalhe>
            <detalhe titulo="Total de Contrapartida Financeira" :size="sizeForm">{{ relatorioFinanceiro?.totalContraPartidaFinanceira }}</detalhe>
            <detalhe titulo="Total da Receita de Acordos" :size="sizeForm">{{ relatorioFinanceiro?.totalReceita }}</detalhe>
            <detalhe titulo="Total Aporte do Sebrae" :size="sizeForm">{{ relatorioFinanceiro?.totalAporteSebrae }}</detalhe>
        </div>
    </painel>
</template>

<script>
export default {

    data() {
        return {
            
        };
    },

    props: {
        relatorioFinanceiro: {
            type: Object,
            default: null,
        },
    },

    computed: {
        sizeForm() {
            return '200';
        },
    },
};
</script>
