<template>
    <div>
        <Card>
            <template #title> Menu Principal </template>

            <template #content>
                <div class="formgrid grid p-fluid">
                    <div class="field col-3">
                        <label>Tipo</label>
                        <Dropdown :options="comboTipoPesquisa" optionLabel="label" :filter="true" optionValue="key" v-model="tipoPesquisa" />
                    </div>
                </div>

                <div class="formgrid grid p-fluid">
                    <div class="field col-3" v-if="tipoPesquisa == 1">
                        <label>CPF</label>
                        <div class="p-inputgroup">
                            <InputMask v-model="cpf" mask="999.999.999-99" :unmask="true" />
                        </div>
                    </div>
                    <div class="field col-3" v-if="tipoPesquisa == 1">
                        <label>Interlocutores</label>
                        <div class="p-inputgroup">
                            <Dropdown :options="comboInterlocutor" optionLabel="nome" :filter="true" optionValue="nome" v-model="nomeInterlocutor" />
                        </div>
                    </div>
                    <div class="field col-3" v-if="tipoPesquisa == 2">
                        <label>CNPJ</label>
                        <InputMask v-model="cnpj" mask="99.999.999/9999-99" :unmask="true" />
                    </div>
                    <div class="field col-3" v-if="tipoPesquisa == 2">
                        <label>Parceiro</label>
                        <div class="p-inputgroup">
                            <Dropdown :options="comboParceiro" optionLabel="razaoSocial"  :filter="true" optionValue="razaoSocial" v-model="razaoSocial" />
                        </div>
                    </div>
                    <div class="field col-3" v-if="tipoPesquisa == 2">
                        <label>Situação</label>
                        <div class="p-inputgroup">
                            <Dropdown :options="comboSituacao" optionLabel="label"  :filter="true" optionValue="key" v-model="situacao" />
                        </div>
                    </div>
                </div>

                <div class="formgrid grid p-fluid" v-if="tipoPesquisa == 3">
                    <div class="field col-3" >
                        <label>Nome Parceiro</label>
                        <div class="p-inputgroup">
                            <Dropdown :options="comboParceiro" optionLabel="razaoSocial" :filter="true" optionValue="id" v-model="parceiroId" />
                        </div>
                    </div>
                    <div class="field col-3" >
                        <label>Unidade Regional </label>
                        <div class="p-inputgroup">
                            <Dropdown :options="comboUnidadeRegional" optionLabel="descricao" :filter="true" optionValue="id" v-model="unidadeRegionalId" />
                        </div>
                    </div>
                    <div class="field col-2" >
                        <label>Status</label>
                        <div class="p-inputgroup">
                            <Dropdown v-model="status" :options="comboStatus" optionLabel="label" :filter="true" optionValue="key" />
                        </div>
                    </div>
                    <div class="field col-2" v-if="tipoPesquisa == 3">
                        <label>Gerente/Analista</label>
                        <div class="p-inputgroup">
                            <Dropdown v-model="gerenteAnalistaId" :options="comboGerenteAnalista" :filter="true" optionLabel="nome" optionValue="id" />
                        </div>
                    </div>
                    <div class="field col-2" v-if="tipoPesquisa == 3">               
                        <label>Tags </label>
                        <div class="p-inputgroup">
                            <InputText 
                                type="text" v-model="tags"/>
                        </div>              
                    </div>
                </div>
                <div class="formgrid grid p-fluid" v-if="tipoPesquisa == 3">
                    <div class="field col-3" >
                        <label>Numero</label>
                        <div class="p-inputgroup">
                            <InputText type="text" v-model="numeroAcordo" />
                        </div>
                    </div>
                    <div class="field col-3">
                        <label>Evento</label>
                        <div class="p-inputgroup">
                            <InputText type="text" v-model="evento" />
                        </div>
                    </div>
                    <div class="field col-3">
                        <label>Data Início Parceria</label>
                        <div class="p-inputgroup">
                            <Calendar :showIcon="true" v-model="dataInicioParceria" />
                        </div>
                    </div>
                    <div class="field col-3" >
                        <label>Data Fim Parceria</label>
                        <div class="p-inputgroup">
                            <Calendar :showIcon="true" v-model="dataFimParceria" />
                        </div>
                    </div>
                </div>

                <div class="formgrid grid p-fluid" v-if="tipoPesquisa == 3">
                    
                    <div class="field col-3">   
                        <label class="required">Eixo</label>
                        <Dropdown v-model="eixo" :filter="true" :options="comboEixos" optionLabel="label" optionValue="key" />
                    </div>
                    <div class="field col-3" >   
                        <label class="required">Sub Eixos</label>
                        <Dropdown v-model="subEixo" :filter="true" :options="comboSubEixos" optionLabel="label" optionValue="key" />
                    </div>  
                </div>
                <painel titulo="Filtros Financeiro" icone="pi pi-sliders-h" v-if="tipoPesquisa == 3" :mostrarRefresh=false>
                    <Accordion :multiple="true" :activeIndex="0">
                        <AccordionTab header="Filtros">
                            <div class="formgrid grid p-fluid">
                                <!-- <div class="field col-2">
                                    <label>Valor Total do Acordo De</label>
                                    <div class="p-inputgroup">
                                        <InputNumber v-model="valorTotalAcordoDe" mode="currency" currency="BRL" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" />
                                    </div>
                                </div>
                                <div class="field col-2" >
                                    <label>Valor Total do Acordo Até</label>
                                    <div class="p-inputgroup">
                                        <InputNumber v-model="valorTotalAcordoAte" mode="currency" currency="BRL" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" />
                                    </div>
                                </div> -->
                                <div class="field col-2">
                                    <label>Aporte Sebrae De</label>
                                    <div class="p-inputgroup">
                                        <InputNumber v-model="aporteSebraeDe" mode="currency" currency="BRL" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" />
                                    </div>
                                </div>
                                <div class="field col-2" >
                                    <label>Aporte Sebrae Até</label>
                                    <div class="p-inputgroup">
                                        <InputNumber v-model="aporteSebraeAte" mode="currency" currency="BRL" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" />
                                    </div>
                                </div>
                                <div class="field col-2">
                                    <label>Contra Partida De</label>
                                    <div class="p-inputgroup">
                                        <InputNumber v-model="contraPartidaDe" mode="currency" currency="BRL" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" />
                                    </div>
                                </div>
                                <div class="field col-2" >
                                    <label>Contra Partida Até</label>
                                    <div class="p-inputgroup">
                                        <InputNumber v-model="contraPartidaAte" mode="currency" currency="BRL" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" />
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </painel>
            </template>
            <template #footer>
                <Button icon="pi pi-refresh" label="Pesquisar" @click="pesquisar()" v-if="!habilitarTabela" class="ml-2" />
                <Button icon="pi pi-refresh" label="Limpar Filtro" @click="voltar()" v-if="habilitarTabela" class="ml-2" />
            </template>
        </Card>
        <div class="mt-4">
            <interlocutores-listagem :filtroInterlocutor="filtroInterlocutor" v-if="tipoPesquisa == 1 && habilitarTabela"></interlocutores-listagem>
            <parceiros-listagem :filtroParceiro="filtroParceiro" v-if="tipoPesquisa == 2 && habilitarTabela"></parceiros-listagem>
            <detalhes-relatorio-consolidado :relatorioFinanceiro="relatorioFinanceiro" v-if="tipoPesquisa == 3 && habilitarTabela"></detalhes-relatorio-consolidado>
            <parcerias-listagem :filtroParceria="filtroParceria" v-if="tipoPesquisa == 3 && habilitarTabela" @enviarRelatorio="enviarRelatorio($event)"></parcerias-listagem>
        </div>
    </div>
</template>

<script>
import Services from '../service.js';
import InterlocutoresListagem from '../interlocutores/lista/ListaInterlocutores.vue';
import ParceriasListagem from '../parceria/lista/ListaParceria.vue';
import DetalhesRelatorioConsolidado from './Relatorio/RelatorioConsolidado.vue';
import ParceirosListagem from '../pessoas/lista/ListaParceiros.vue';

export default {
    components: {
        InterlocutoresListagem,
        ParceriasListagem,
        ParceirosListagem,
        DetalhesRelatorioConsolidado,
    },

    data() {
        return {
            valorTotalAcordoAte: null,
            valorTotalAcordoDe: null,
            dataExecussaoInicial: '',
            dataInicioParceria: '',
            dataExecussaoFinal: '',
            filtroInterlocutor: null,
            comboUnidadeRegional: [],
            comboGerenteAnalista: [],
            unidadeRegionalId: null,
            gerenteAnalistaId: null,
            contraPartidaAte: null,
            habilitarTabela: false,
            aporteSebraeAte: null, 
            contraPartidaDe: null, 
            comboInterlocutor: [],
            aporteSebraeDe: null, 
            dataFimParceria: '',
            filtroParceria: null,
            filtroParceiro: null,
            nomeInterlocutor: '',
            relatorioFinanceiro: null,
            tipoPesquisa: null,
            gestorRegional: '',
            comboParceiro: [],
            numeroAcordo: '',
            nomeParceiro: '',
            razaoSocial: '',
            parceiroId: '',
            evento: '',
            status: null,
            tags: '',
            cpf: '',
            cnpj: '',
            situacao: null,
            comboSituacao: [
                {
                    key: '0',
                    label: 'ADIMPLÊNCIA',
                },
                {
                    key: '1',
                    label: 'INADIMPLÊNCIA',
                }
            ],
            comboTipoPesquisa: [
                {
                    key: '1',
                    label: 'INTERLOCUTOR',
                },
                {
                    key: '2',
                    label: 'PARCEIRO',
                },
                {
                    key: '3',
                    label: 'PARCERIA',
                },
            ],
            comboStatus: [
                {
                    key: '0',
                    label: 'EM ELABORAÇÃO',
                },
                {
                    key: '1',
                    label: 'EM EXECUÇÃO',
                },
                {
                    key: '2',
                    label: 'CANCELADO',
                },
                {
                    key: '3',
                    label: 'CONCLUÍDO'
                },
                {
                    key: '4',
                    label: 'SUSPENSO',
                },
            ],
            comboEixos: [
                {
                    key: 1,
                    label: 'Ambiente Competitivo e Gestão Empresarial',
                },
                {
                    key: 2,
                    label: 'Inovação Empresarial',
                },
                {
                    key: 3,
                    label: 'Estudos e Diagnósticos',
                },
                {
                    key: 4,
                    label: 'Acesso a Mercados',
                },
                {
                    key: 5,
                    label: 'Capacitação Técnica',
                },
                {
                    key: 6,
                    label: 'Políticas Públicas e Ambiente de Negócios - PP/AN',
                },
            ],
            comboSubEixos: [
                {
                    key: 1,
                    label: 'PP/AN - Gestão Municipal',
                },
                {
                    key: 2,
                    label: 'PP/AN - Lideranças Locais',
                },
                {
                    key: 3,
                    label: 'PP/NA - Desburocratização',
                },
                {
                    key: 4,
                    label: 'PP/AN - Sala do Empreendedor',
                },
                {
                    key: 5,
                    label: 'PP/AN - Compras Governamentais',
                },
                {
                    key: 6,
                    label: 'PP/AN - Empreendedorismo na Escola',
                },
                {
                    key: 7,
                    label: 'PP/AN - Inclusão Produtiva',
                },
                {
                    key: 8,
                    label: 'PP/AN - Marketing Territorial e Setores Produtivos',
                },
                {
                    key: 9,
                    label: 'PP/AN - Cooperativismo e Crédito',
                },
                {
                    key: 10,
                    label: 'PP/AN - Inovação e Sustentabilidade',
                },
            ],
            eixo: '',
            subEixo: '',
        };
    },

    methods: {
        limparFiltros() {
            this.relatorioFinanceiro = null,
            this.dataExecussaoInicial = '',
            this.valorTotalAcordoAte = '',
            this.valorTotalAcordoDe = '',
            this.dataExecussaoFinal = '',
            this.dataInicioParceria = '',
            this.unidadeRegionalId = '',
            this.nomeInterlocutor = '',
            this.contraPartidaAte = '',
            this.contraPartidaDe = '',
            this.aporteSebraeAte = '',
            this.dataFimParceria = '',
            this.aporteSebraeDe = '',
            this.gestorRegional = '',
            this.numeroAcordo = '',
            this.nomeParceiro = '',
            this.situacao = '',
            this.razaoSocial = '',
            this.parceiroId = '',
            this.status = '',
            this.evento = '',
            this.tags = '',
            this.cnpj = '',
            this.cpf = '',
            this.eixo = '',
            this.subEixo = '';
        },

        voltar() {
            this.habilitarTabela = false;
            this.limparFiltros();
        },

        pesquisar() {
            this.habilitarTabela = true;
            if (this.tipoPesquisa == 1) {
                this.filtroInterlocutor = {
                    cpf: this.cpf,
                    nomeInterlocutor: this.nomeInterlocutor,
                };
            } else if (this.tipoPesquisa == 2) {
                this.filtroParceiro = {
                    cnpj: this.cnpj,
                    razaoSocial: this.razaoSocial,
                    situacao: this.situacao
                };
            } else if (this.tipoPesquisa == 3) {
                this.filtroParceria = {
                    dataExecussaoInicial: this.dataExecussaoInicial,
                    dataInicioParceria: this.dataInicioParceria,
                    dataExecussaoFinal: this.dataExecussaoFinal,
                    unidadeRegionalId: this.unidadeRegionalId,
                    valorTotalAcordoAte: this.valorTotalAcordoAte,
                    valorTotalAcordoDe: this.valorTotalAcordoDe,
                    contraPartidaAte: this.contraPartidaAte,
                    contraPartidaDe: this.contraPartidaDe,
                    aporteSebraeAte: this.aporteSebraeAte,
                    gerenteAnalistaId: this.gerenteAnalistaId,
                    dataFimParceria: this.dataFimParceria,
                    aporteSebraeDe: this.aporteSebraeDe,
                    numeroAcordo: this.numeroAcordo,
                    parceiroId: this.parceiroId,
                    evento: this.evento,
                    status: this.status,
                    tags: this.tags,
                    eixo: this.eixo,
                    subEixo: this.subEixo
                };
            } 
        },

        carregaComboParceiro() {
            this.$store.dispatch('addRequest');
            var filtro = {
                cnpj: '',
                razaoSocial: '',
                situacao: ''
            };
            Services.SelectAllParceiros(filtro).then((response) => {
                if (response && response.success) {
                    this.comboParceiro = response.data;
                } else {
                    this.comboParceiro = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        carregaComboInterlocutor() {
            this.$store.dispatch('addRequest');
            var filtro = {
                cpf: '',
                nomeInterlocutor: '',
            };
            Services.SelectAllInterlocutores(filtro).then((response) => {
                if (response && response.success) {
                    this.comboInterlocutor = response.data;
                } else {
                    this.comboInterlocutor = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        carregaComboUnidadeRegional() {
            this.$store.dispatch('addRequest');
            Services.SelectAllUnidadesRegionais().then((response) => {
                if (response && response.success) {
                    this.comboUnidadeRegional = response.data;
                } else {
                    this.comboUnidadeRegional = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        carregaComboGerenteAnalista() {
            this.$store.dispatch('addRequest');
            Services.SelectAllFuncionario().then((response) => {
                if (response && response.success) {
                    this.comboGerenteAnalista = response.data;
                } else {
                    this.comboGerenteAnalista = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        enviarRelatorio(relatorio)
        {
            this.relatorioFinanceiro = relatorio;
        }
    },

    mounted() {
        this.carregaComboParceiro();
        this.carregaComboInterlocutor();
        this.carregaComboUnidadeRegional();
        this.carregaComboGerenteAnalista();
    },

    watch: {
        tipoPesquisa() {
            this.habilitarTabela = false;
            this.limparFiltros();
        },
    },
};
</script>